<template>
    <div class="inline-block">
        <vs-button @click="activeWindow=true" color="primary" type="border" icon="edit"></vs-button>
        <vs-popup class="holamundo" title="Редактирование параметров" :active.sync="activeWindow">
            <template v-if="localImage">

                <!-- Image Container -->
                <div class="img-container w-64 mx-auto flex items-center justify-center">
                    <img :src="localImage" alt="img" class="responsive">
                </div>

                <!-- Image upload Buttons -->
                <div class="modify-img flex justify-between mt-5">
                    <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
                    <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">Заменить
                    </vs-button>
                    <vs-button type="flat" color="#999" @click="localImage = null">Удалить</vs-button>
                </div>
            </template>

            <div class="upload-img mt-5" v-else>
                <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
                <vs-button @click="$refs.uploadImgInput.click()">Загрузить изображение</vs-button>
            </div>
            <div class="inline-block mt-5">
                <vs-input-number
                    min="0"
                    v-model="localCount"
                    class="mt-5"
                    @input="$emit('update:count', localCount)"/>
            </div>
            <vs-input
                icon-pack="fas"
                icon="fa-ruble-sign"
                label="Входящая цена"
                v-model="localEnterPrice"
                @input="$emit('update:enterPrice', localEnterPrice)"
                class="mt-5 w-full"/>
            <vs-input
                icon-pack="fas"
                icon="fa-ruble-sign"
                label="Старая цена"
                v-model="localOldPrice"
                @input="$emit('update:oldPrice', localOldPrice)"
                class="mt-5 w-full"/>
            <vs-input
                icon-pack="fas"
                icon="fa-ruble-sign"
                label="Цена"
                v-model="localPrice"
                class="mt-5 w-full"
                @input="$emit('update:price', localPrice)"
                name="item-price"/>
        </vs-popup>
    </div>
</template>

<script>
    import axios from "@axios";

    export default {
        props: [
            'enterPrice',
            'price',
            'oldPrice',
            'image',
            'count'
        ],
        name: "ProductPrices",
        data() {
            return {
                activeWindow: false,
                localPrice: this.price,
                localOldPrice: this.oldPrice,
                localEnterPrice: this.enterPrice,
                localImage: this.image,
                localCount: this.count
            }
        },
        methods: {
            updateCurrImg(input) {
                if (input.target.files && input.target.files[0]) {
                    const reader = new FileReader()
                    reader.readAsDataURL(input.target.files[0])
                    let formData = new FormData();
                    formData.append('image', input.target.files[0]);
                    axios.post('shop/products/files/add',
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then(function (response) {
                        this.$vs.notify({
                            color: 'success',
                            title: 'Успешная загрузка',
                            text: 'Изображение было успешно загружено'
                        })
                        this.localImage = response.data.url
                        this.$emit('update:image', this.localImage)
                    })
                    .catch(function () {
                        this.$vs.notify({
                            color: 'danger',
                            title: 'Ошибка загрузки',
                            text: 'Изображение не было загружено'
                        })
                    });
                }
            }
        }
    }
</script>
