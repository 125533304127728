<template>
    <vs-sidebar position-right parent="body" default-index="1" color="primary" click-not-close
                class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
        <div class="mt-6 flex items-center justify-between px-6">
            <h4>{{ Object.entries(this.data).length === 0 ? 'Добавление' : 'Редактирование' }} товара</h4>
            <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
        </div>
        <vs-divider class="mb-0"></vs-divider>

        <component v-if="currentMode == 0" :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings"
                   :key="$vs.rtl">


            <div style="width: 100%" class="p-6">

                <vs-button @click="currentMode=2" class="mt-3" size="small">
                    Связи товара
                </vs-button>

                <vs-button @click="currentMode=1" class="mt-3" size="small">
                    Управление опциями
                </vs-button>

                <!-- Product Image -->
                <!-- <template v-if="images">
                    <div class="img-container w-64 mx-auto flex items-center justify-center">
                        <img :src="image" alt="img" class="responsive">
                    </div>
                    <div class="modify-img flex justify-between mt-5">
                        <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
                        <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">Заменить
                        </vs-button>
                        <vs-button type="flat" color="#999" @click="image = null">Удалить</vs-button>
                    </div>
                </template> -->

                <div class="upload-img mt-5" v-if="!images">
                    <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
                    <vs-button @click="$refs.uploadImgInput.click()">Загрузить изображение</vs-button>
                </div>

                <vs-divider position="left">Информация о товаре</vs-divider>

                <!-- NAME -->
                <vs-input label="Название" v-model="name" class="mt-5 w-full" name="product_name"
                          v-validate="'required'"/>
                <!-- <span class="text-danger text-sm"
                      v-show="errors.has('product_name')">{{ errors.first('product_name') }}</span> -->
                <vs-input label="Вкус/цвет" v-model="color" class="mt-5 w-full"/>
                <vs-input label="Кол-во" v-model="quantity" class="mt-5 w-full" name="product_count"
                          v-validate="'required'"/>
                <!-- <span class="text-danger text-sm"
                      v-show="errors.has('product_count')">{{ errors.first('product_count') }}</span> -->
                <!-- <vs-input label="Артикул поставщика" v-model="sku" class="mt-5 w-full"/> -->
                <vs-input label="Бренд" v-model="brand" class="mt-5 w-full"/>
                <vs-input label="Модель" v-model="model" class="mt-5 w-full"/>
                <vs-input label="Штрихкод" v-model="barcode" class="mt-5 w-full"/>
                <!-- PRICE -->
                <!-- <vs-input
                    icon-pack="fas"
                    icon="fa-ruble-sign"
                    label="Входящая цена"
                    v-model="enterPrice"
                    class="mt-5 w-full"/> -->
                <vs-input
                    icon-pack="fas"
                    icon="fa-ruble-sign"
                    label="Скидочная цена"
                    v-model="salePrice"
                    class="mt-5 w-full"/>
                <vs-input
                    icon-pack="fas"
                    icon="fa-ruble-sign"
                    label="Цена"
                    v-model="purchasePrice"
                    class="mt-5 w-full"
                    name="item-price"/>
                <!-- <span class="text-danger text-sm"
                      v-show="errors.has('item-price')">{{ errors.first('item-price') }}</span> -->

                <v-select class="mt-5" multiple v-model="category" @input="changeCategory" :options="categories"
                          label="title"/>

                <!-- <span class="text-danger text-sm" v-show="errors.has('item-category')">{{ errors.first('item-category') }}</span> -->

                <vs-divider position="left">Описание товара</vs-divider>
                <quill-editor v-model="description"></quill-editor>
            </div>
        </component>

        <component v-else-if="currentMode===2" :is="scrollbarTag" class="scroll-area--data-list-add-new"
                   :settings="settings" :key="$vs.rtl">
            <div style="width: 100%" class="p-6">
                <vs-button @click="currentMode=0" class="mb-5" size="small">
                    Вернуться к товару
                </vs-button>

                <!-- <vs-divider position="left">По полу</vs-divider>
                <vs-input placeholder="Код связи по полу" v-model="link_sex_code" class="mb-5 inline-block"
                          name=""/>

                <vs-divider position="left">По цвету/вкусу</vs-divider>
                <vs-input placeholder="Код связи по цвету/вкусу" v-model="link_color_taste_code"
                          class="mb-5 inline-block"
                          name=""/> -->

            </div>
        </component>

        <!-- <component v-else :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
            <div style="width: 100%" class="p-6">
                <vs-button @click="currentMode=0" class="mb-5" size="small">
                    Вернуться к товару
                </vs-button>

                <vs-divider position="left">Размер/Объём</vs-divider>

                <div class="" v-for="(option, index) in sizeOptions" :key="'sizeOptions'+index">
                    <span>{{index+1}}</span>
                    <vs-input placeholder="Значение" v-model="option.value" class="mb-5 inline-block"
                              name=""/>
                    <product-prices :price.sync="option.price" :oldPrice.sync="option.salePrice"
                                    :enterPrice.sync="option.enterPrice"
                                    :count.sync="option.count"
                                    :image.sync="option.image"></product-prices>
                    <vs-button @click="sizeOptions.splice(index,1)"
                               title="Удалить"
                               class="inline-block"
                               color="primary" type="flat" icon-pack="feather"
                               icon="icon-minus"></vs-button>
                    <vs-divider position="left">Упаковки</vs-divider>
                    <div class="" v-for="(package_i, indx) in option.packages" :key="'color-option.packages'+indx">
                        <span>{{i+1}}.{{index+1}}.{{indx+1}}</span>
                        <vs-input placeholder="Значение" v-model="package_i.value" class="mb-5 inline-block"
                                  name=""/>
                        <product-prices :price.sync="package_i.price" :oldPrice.sync="package_i.salePrice"
                                        :enterPrice.sync="package_i.enterPrice"
                                        :count.sync="package_i.count"
                                        :image.sync="package_i.image"></product-prices>
                        <vs-button @click="option.packages.splice(index,1)"
                                   title="Удалить"
                                   class="inline-block"
                                   color="primary" type="flat" icon-pack="feather"
                                   icon="icon-minus"></vs-button>
                    </div>
                    <vs-button
                        @click="option.packages.push({value:'',count:0, image:'', price:'',salePrice:'',enterPrice:''})"
                        class="mb-3" size="small">
                        Добавить упаковку
                    </vs-button>
                </div>
                <vs-button
                    @click="sizeOptions.push({value:'',count:0, image:'', price:'',salePrice:'',enterPrice:'', packages: []})"
                    class="mb-3" size="small">
                    Добавить размер
                </vs-button>

                <vs-divider position="left">Опции товара</vs-divider>

                <div class="" v-for="(group, i) in options" :key="'options'+i">
                    <span>{{i+1}}.</span>
                    <vs-input placeholder="Опция" v-model="group.name" class="mb-5 inline-block"
                              name=""/>
                    <vs-button @click="options.splice(i,1)"
                               title="Удалить"
                               class="inline-block"
                               color="primary" type="flat" icon-pack="feather"
                               icon="icon-minus"></vs-button>

                    <div class="" v-for="(option, index) in group.values" :key="'group.values'+index">
                        <span>{{i+1}}.{{index+1}}</span>
                        <vs-input placeholder="Значение" v-model="option.value" class="mb-5 inline-block"
                                  name=""/>
                        <vs-button @click="group.values.splice(index,1)"
                                   title="Удалить"
                                   class="inline-block"
                                   color="primary" type="flat" icon-pack="feather"
                                   icon="icon-minus"></vs-button>
                    </div>
                    <vs-button @click="group.values.push({value:'',count:0})" class="mb-3" size="small">
                        Добавить значение
                    </vs-button>
                </div>
                <vs-button @click="options.push({name:'',values:[]})" class="mb-3" size="small">
                    Добавить опцию
                </vs-button>
            </div>
        </component> -->

        <div class="flex flex-wrap items-center p-6" slot="footer">
            <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">{{Object.entries(this.data).length ===
                0 ? 'Добавить' : 'Сохранить'}}
            </vs-button>
            <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Отмена</vs-button>
        </div>
    </vs-sidebar>
</template>

<script>
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'

  import { quillEditor } from 'vue-quill-editor'
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import vSelect from 'vue-select'
  import ProductPrices from './ProductPrices.vue'

  import flatPickr from 'vue-flatpickr-component'
  import 'flatpickr/dist/flatpickr.css'

  import axios from '@axios'

  export default {
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        }
      },
      categories: {
        type: Array,
        default: () => []
      }
    },
    components: {
      VuePerfectScrollbar,
      flatPickr,
      quillEditor,
      ProductPrices,
      'v-select': vSelect
    },
    data () {
      return {
        id: null,
        name: '',
        description: '',
        category: null,
        categoryId: null,
        images: '',
        purchasePrice: '',
        salePrice: '',
        barcode: '',
        gender: false,
        color: '',
        model: '',
        brand: '',
        code: '',
        quantity: 0,
        currentMode: 0,
        status: 'pending',

        sizeOptions: [],

        settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60
        },
        configdateTimePicker: {
          enableTime: true,
          dateFormat: 'd-m-Y H:i'
        }
      }
    },
    watch: {
      isSidebarActive (val) {
        if (!val) return
        if (Object.entries(this.data).length === 0) {
          this.initValues()
          this.$validator.reset()
        } else {
          console.log(this.data)
          const {
            id, name, description, quantity, purchase_price, sale_price, barcode128, images, b1_category, b2_category, b3_category, gender, model, color, brand, taste
          } = JSON.parse(JSON.stringify(this.data))
          this.id = id
          this.name = name
          this.description = description
          this.purchasePrice = purchase_price
          this.salePrice = sale_price
          this.quantity = quantity
          this.barcode = barcode128
          this.category = b1_category ? [b1_category, b2_category, b3_category] : []
          this.images = images
          this.taste = taste
          this.gender = gender
          this.color = color
          this.model = model
          this.brand = brand
          // this.initValues()
        }
        // this.$store.dispatch('shop/fetchCategories')
        // this.$store.dispatch('shop/fetchDataById', {id: this.id})
        // .then(response => {
        //   console.log(response)
        //   this.category = response.data.start_data.category.slice()
        //   this.sizeOptions = response.data.start_data.sizeOptions.slice()
        //   this.options = response.data.start_data.options.slice()
        // })
        // Object.entries(this.data).length === 0 ? this.initValues() : { this.data_id, this.data_name, this.data_category, this.data_status, this.price } = JSON.parse(JSON.stringify(this.data))
      }
    },
    computed: {
      axiosHeader () {
        return {'X-CSRF-TOKEN': document.querySelector('meta[name=\'csrf-token\']').content}
      },
      isTastesActive () {
        return Object.entries(this.tasteOptions).length !== 0
      },
      isSidebarActiveLocal: {
        get () {
          return this.isSidebarActive
        },
        set (val) {
          if (!val) {
            this.$emit('closeSidebar')
            // this.$validator.reset()
            // this.initValues()
          }
        }
      },
      isFormValid () {
        return true
      },
      scrollbarTag () {
        return VuePerfectScrollbar
      }
    },
    methods: {
      changeCategory (e) {
        if (Object.entries(this.data).length !== 0) return false
        const $this = this
        let temp
        this.options = []
        for (let cat of e) {
          if (!cat.options) continue
          temp = cat.options.forEach(function (item) {
            if (!item.standard) return false
            if (item.name === 'Вкус') {
              item.standard.forEach(function (itm) {
                console.log(itm)
                $this.tasteOptions.push({
                  name: itm.value,
                  count: 0
                })
              })
              return false
            }
            if (item.name === 'Цвет') {
              item.standard.forEach(function (itm) {
                $this.colorOptions.push({
                  name: itm.value,
                  count: 0
                })
              })
              return false
            }

            let standard = item.standard.map(function (el) {
              return {
                value: el.value
              }
            })

            $this.options.push({
              name: item.name,
              id: item.id,
              option_id: item.option_id,
              values: standard
            })
          })
        }
      },
      initValues () {
        if (this.data.id) return
        this.id = null
        this.name = ''
        this.description = ''
        this.purchasePrice = ''
        this.salePrice = ''
        this.quantity = 0
        this.barcode = ''
        this.category = ''
        this.images = ''
        this.qender = false
        this.color = false
        this.model = ''
        this.brand = ''
      },
      submitData () {
        const obj = {
          id: this.id,
          name: this.name,
          description: this.description,
          purchase_price: this.purchasePrice,
          sale_price: this.salePrice,
          quantity: this.quantity,
          barcode128: this.barcode,
          // category: typeof this.category === 'object' ? this.category : '',
          images: this.images,
          taste: this.taste,
          gender: this.gender,
          color: this.color,
          model: this.model,
          brand: this.brand
        }
        if (this.id !== null && this.id >= 0) {
          console.log(obj)
          this.$store.dispatch('shop/updateItem', obj)
            .then(() => {
              this.$vs.notify({
                title: 'Товар обновлен',
                text: 'Товар был успешно обновлен',
                color: 'success'
              })
            })
            .catch(err => {
              console.error(err)
              this.$vs.notify({
                title: 'Ошибка',
                text: 'Ошибка при сохранении',
                color: 'danger'
              })
            })
        } else {
          delete obj.id
          this.$store.dispatch('shop/addItem', obj)
            .then(() => {
              this.$vs.notify({
                title: 'Товар добавлен',
                text: 'Товар был успешно добавлен',
                color: 'success'
              })
            })
            .catch(err => {
              console.error(err)
              this.$vs.notify({
                title: 'Ошибка',
                text: 'Ошибка при добавлении товара',
                color: 'danger'
              })
            })
        }
        this.$emit('closeSidebar')
        this.initValues()
      },
      updateCurrImg (input) {
        if (input.target.files && input.target.files[0]) {
          const data = new FormData()
          data.append('image', input.target.files[0])
          data.append('name', this.link_color_taste_code)
          axios.post('shop/products/files/add', data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
            .then(response => {
              console.log(response)
              this.image = response.data.url
            })
            .catch(error => {
              this.$vs.notify({
                color: 'error',
                title: 'Ошибка',
                text: 'Не удалось загрузить файл'
              })
            })

          // const reader = new FileReader()
          // reader.onload = e => {
          //   this.image = e.target.result
          // }
          // reader.readAsDataURL(input.target.files[0])
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    .fade-tab-enter-active, .fade-tab-leave-active {
        transition: all .3s;
    }

    .fade-tab-enter, .fade-tab-leave-to /* .fade-tab-leave-active below version 2.1.8 */
    {
        top: 0;
        opacity: 0;
        position: absolute !important;
        transform: translate3d(-100%, 0, 0);
    }

    .fade-tab-leave-to /* .fade-tab-leave-active below version 2.1.8 */
    {
        top: 0;
        opacity: 0;
        position: absolute !important;
        transform: translate3d(100%, 0, 0);
    }


    .tabs-day-block {
        position: relative;
    }

    .add-new-data-sidebar {
        ::v-deep .vs-sidebar--background {
            z-index: 52010;
        }

        ::v-deep .vs-sidebar {
            z-index: 52010;
            width: 100%;
            max-width: 520px;

            @media screen and (max-width: 1200px) {
                max-width: 100%;
            }

            .img-upload {
                margin-top: 2rem;

                .con-img-upload {
                    padding: 0;
                }

                .con-input-upload {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }

    .scroll-area--data-list-add-new {
        // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
        height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

        &:not(.ps) {
            overflow-y: auto;
        }
    }
</style>
