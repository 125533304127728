<template>
  <div class="product-container">
    <vue-good-table
      :columns="columns"
      :rows="rows"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <router-link
                :to="{ name: 'shop-order', params: { id: props.row.id } }"
                class="edit-button dropdown-item"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Редактировать</span>
              </router-link>

              <b-dropdown-item
                v-b-modal.modal-primary
                variant="outline-primary"
                @click="acceptDelete(props.row.id)"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Удалить</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>

    <infinite-loading
      spinner="bubbles"
      @infinite="infiniteScroll"
    >
      <div slot="no-more" />
      <div slot="no-results" />
    </infinite-loading>
  </div>
</template>

<script>
import {
  BBadge, BButton, BDropdown, BDropdownItem, BFormCheckbox, BFormGroup, BFormInput, BModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import InfiniteLoading from 'vue-infinite-loading'
import DataViewSidebar from '../DataViewSidebar.vue'
import DataOptionEdit from '../DataOptionEdit.vue'
import ModalWindow from '../../common/ModalWindow.vue'
import ProductInfo from '../ProductInfo.vue'

export default {
  components: {
    BModal,
    DataViewSidebar,
    DataOptionEdit,
    VueGoodTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    ModalWindow,
    ProductInfo,
    InfiniteLoading,
  },
  data() {
    return {
      selected: [],
      currentDelete: null,
      skipCount: 0,
      maxResultCount: 30,
      addNewDataSidebar: false,
      addNewOptionSidebar: false,
      isEditModalOpen: false,
      sidebarData: {},
      optionData: {},
      rows: [],
      columns: [
        {
          label: 'Заказчик',
          field: this.formatUserName,
          width: '200px',
        },
        {
          label: 'Дата оформления',
          field: 'createdAt',
          formatFn: this.formatOrderDate,
          width: '150px',
        },
        {
          label: 'Цена',
          field: 'cost',
          formatFn: this.formatPrice,
          width: '150px',
        },
        {
          label: 'Тип оплаты',
          field: 'payment_method',
          formatFn: this.formatPaymentType,
          width: '150px',
        },
        {
          label: 'Статус',
          field: 'status',
          formatFn: this.formatStatus,
          width: '150px',
        },
        {
          label: 'Действие',
          field: 'action',
          width: '50px',
        },
      ],
      showModal: false,
    }
  },
  created() {
    this.$store.dispatch('shop/fetchOrdersList')
  },
  methods: {
    formatPrice(cost) {
      return Math.trunc(Number(cost) / 100)
    },
    formatPaymentType(payment_method) {
      switch (payment_method) {
        case 'on_receipt':
          return 'Наличными'
        case 'online':
          return 'Онлайн'
        default:
          return 'Наличными'
      }
    },
    formatStatus(status) {
      switch (status) {
        case 'completed':
          return 'Оплачено'
        case 'confirmed':
          return 'Подтверждён'
        case 'pending':
          return 'В ожидании'
        case 'cancelled':
          return 'Отменен'
        default:
          return 'Без статуса'
      }
    },
    formatUserName(row) {
      if (!row.user.name && !row.user.surname) {
        return 'Неизвестный'
      }

      return `${row.user.name} ${row.user.surname}`
    },
    formatOrderDate(createdAt) {
      return new Date(createdAt).toLocaleString('ru', { year: 'numeric', month: 'long', day: 'numeric' })
    },
    async infiniteScroll($state) {
      const payload = {
        offset: this.skipCount,
        limit: this.maxResultCount,
      }
      this.$store
        .dispatch('shop/fetchOrdersList', payload)
        .then(() => {
          if (this.$store.state.shop.orders.length > 0) {
            this.rows.push(...this.$store.state.shop.orders)
            this.skipCount += this.maxResultCount
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    deleteData() {
      this.$store.dispatch('shop/removeOrder', this.currentDelete).then(() => {
        const index = this.rows.findIndex(el => el.id === this.currentDelete)
        this.rows.splice(index, 1)
      })
    },
    acceptDelete(data) {
      this.currentDelete = data
      this.$vs.dialog({
        color: 'warning',
        title: 'Удаление заказа',
        text: 'Вы уверены, что хотите удалить заказ?',
        accept: this.deleteData,
        acceptText: 'Да',
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table';
</style>

<style lang="scss" scoped>
.container {
  padding: 15px 20px;
}
</style>
