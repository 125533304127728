<template>
    <vs-sidebar position-right parent="body" default-index="1" color="primary" click-not-close
                class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
        <div class="mt-6 flex items-center justify-between px-6">
            <h4>Редактирование опций</h4>
            <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
        </div>
        <vs-divider class="mb-0"></vs-divider>

        <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
            <div style="width: 100%" class="p-6">
                <vs-divider position="left">{{data.name}}</vs-divider>
                <div class="" v-for="(group, i) in options" :key="'options'+i">
                    <vs-input placeholder="Опция" v-model="group.name" class="mt-5 inline-block"
                              name=""/>
                    <vs-button @click="options.splice(i,1)"
                               title="Удалить"
                               class="inline-block"
                               color="primary" type="flat" icon-pack="feather"
                               icon="icon-minus"></vs-button>
                    <div class="pl-5" v-for="(option, index) in group.values" :key="'group.values'+index">
                        <vs-input placeholder="Значение" v-model="option.value" class="mt-5 inline-block"
                                  name=""/>
                        <div class="inline-block">
                            <vs-input-number min="0" v-model="option.count" class="mt-5"/>
                        </div>
                        <div class="inline-block">
                            <vs-input
                                icon-pack="fas"
                                icon="fa-ruble-sign"
                                placeholder="Цена"
                                v-model="option.price"
                                class="mt-5 w-full"/>
                        </div>
                        <vs-button @click="group.values.splice(index,1)"
                                   title="Удалить"
                                   class="inline-block"
                                   color="primary" type="flat" icon-pack="feather"
                                   icon="icon-minus"></vs-button>
                    </div>
                    <vs-button @click="group.values.push({value:'',count:0})" class="mt-3" size="small">
                        Добавить
                        значение
                    </vs-button>
                </div>
                <vs-button @click="options.push({name:'',values:[]})" class="mt-3" size="small">
                    Добавить опцию
                    опцию
                </vs-button>
            </div>

        </component>

        <div class="flex flex-wrap items-center p-6" slot="footer">
            <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Сохранить</vs-button>
            <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Отмена</vs-button>
        </div>
    </vs-sidebar>
</template>

<script>
    import axios from "axios";

    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    import {quillEditor} from 'vue-quill-editor'
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'

    import flatPickr from 'vue-flatpickr-component';
    import 'flatpickr/dist/flatpickr.css';

    export default {
        props: {
            isSidebarActive: {
                type: Boolean,
                required: true
            },
            data: {
                type: Object,
                default: () => {
                }
            },
            categories: {
                type: Array,
                default: () => []
            }
        },
        components: {
            VuePerfectScrollbar,
            flatPickr,
            quillEditor
        },
        data() {
            return {

                id: null,
                options: [],

                settings: { // perfectscrollbar settings
                    maxScrollbarLength: 60,
                    wheelSpeed: .60
                },
                configdateTimePicker: {
                    enableTime: true,
                    dateFormat: 'd-m-Y H:i'
                }
            }
        },
        watch: {
            isSidebarActive(val) {
                if (!val) return
                if (Object.entries(this.data).length === 0) {
                    this.initValues()
                    this.$validator.reset()
                } else {
                    const {
                        id, options
                    } = JSON.parse(JSON.stringify(this.data))
                    this.id = id
                    this.options = []
                    axios.get('shop/suboptions/get/' + id)
                        .then((response) => {
                            this.options = response.data.data
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                    this.initValues()
                }
                // Object.entries(this.data).length === 0 ? this.initValues() : { this.data_id, this.data_name, this.data_category, this.data_status, this.price } = JSON.parse(JSON.stringify(this.data))
            }
        },
        computed: {
            isSidebarActiveLocal: {
                get() {
                    return this.isSidebarActive
                },
                set(val) {
                    if (!val) {
                        this.$emit('closeSidebar')
                        // this.$validator.reset()
                        // this.initValues()
                    }
                }
            },
            isFormValid() {
                return !this.errors.any()
            },
            scrollbarTag() {
                return this.$store.getters.scrollbarTag
            }
        },
        methods: {
            initValues() {
                if (this.id) return
                this.options = []
            },
            submitData() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        const obj = {
                            option_id: this.id,
                            options: this.options
                        }
                        if (this.id !== null && this.id >= 0) {
                            console.log(obj)
                            axios.post('shop/suboptions/edit', {obj})
                                .then((response) => {
                                    console.log(response)
                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                        }
                        this.$emit('closeSidebar')
                        this.initValues()
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .fade-tab-enter-active, .fade-tab-leave-active {
        transition: all .3s;
    }

    .fade-tab-enter, .fade-tab-leave-to /* .fade-tab-leave-active below version 2.1.8 */
    {
        top: 0;
        opacity: 0;
        position: absolute !important;
        transform: translate3d(-100%, 0, 0);
    }

    .fade-tab-leave-to /* .fade-tab-leave-active below version 2.1.8 */
    {
        top: 0;
        opacity: 0;
        position: absolute !important;
        transform: translate3d(100%, 0, 0);
    }


    .tabs-day-block {
        position: relative;
    }

    .add-new-data-sidebar {
        ::v-deep .vs-sidebar--background {
            z-index: 52010;
        }

        ::v-deep .vs-sidebar {
            z-index: 52010;
            width: 100%;
            max-width: 500px;

            @media screen and (max-width: 1200px) {
                max-width: 100%;
            }

            .img-upload {
                margin-top: 2rem;

                .con-img-upload {
                    padding: 0;
                }

                .con-input-upload {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }

    .scroll-area--data-list-add-new {
        // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
        height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

        &:not(.ps) {
            overflow-y: auto;
        }
    }
</style>
